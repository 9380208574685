/**
 * External dependencies
 */
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

const AsideContext = createContext({});

const ASIDE_DEFAULT_EXPANDED = 'true';

const getAsideKey = (aside) => `aside-${aside.id}`;

const updateAsideWidthProperty = (aside, isExpanded) =>
    document.body.style.setProperty(
        `--aside-${aside.id}-width`,
        aside[isExpanded ? 'expandedWidth' : 'collapsedWidth']
    );

const initializeAsideStates = (asides = {}) =>
    Object.values(asides).reduce((acc, aside) => {
        const isExpanded = JSON.parse(localStorage.getItem(getAsideKey(aside)) || ASIDE_DEFAULT_EXPANDED);
        updateAsideWidthProperty(aside, isExpanded); // storage value
        document.body.style.setProperty(
            // default expanded width to control on hover
            `--aside-${aside.id}-width-expanded`,
            aside.expandedWidth
        );
        return { ...acc, [aside.id]: isExpanded };
    }, {});

export const AsidesProvider = ({ children, asides = {} }) => {
    const [expandedAsides, setExpandedAsides] = useState(initializeAsideStates(asides));

    const updateAside = useCallback((aside, value) => {
        setExpandedAsides((prevAsides) => {
            const isExpanded = value !== undefined ? value : !prevAsides[aside.id];
            localStorage.setItem(getAsideKey(aside), isExpanded);
            updateAsideWidthProperty(aside, isExpanded);
            return { ...prevAsides, [aside.id]: isExpanded };
        });
    }, []);

    const asideActions = useMemo(
        () =>
            Object.values(asides).reduce(
                (acc, aside) => ({
                    ...acc,
                    [aside.id]: {
                        toggle: () => updateAside(aside),
                        open: () => updateAside(aside, true),
                        close: () => updateAside(aside, false),
                    },
                }),
                {}
            ),
        [asides, updateAside]
    );

    const providerData = {
        expandedAsides,
        asideActions,
    };

    return <AsideContext.Provider value={providerData}>{children}</AsideContext.Provider>;
};

export const useAsideContext = () => {
    return useContext(AsideContext);
};
