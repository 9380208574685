/**
 * External dependencies
 */
import { usePage, router } from '@inertiajs/react';
import parse from 'html-react-parser';
import isEmpty from 'lodash.isempty';
import { parsePhoneNumber } from 'libphonenumber-js';
import { formatDistanceToNowStrict, differenceInSeconds, format, isToday, isYesterday, isThisWeek } from 'date-fns';
import md5 from 'md5';

/**
 * Internal dependencies
 */
import useMediaQuery from '../hooks/use-media-query';

const truncateText = (text, length) => {
    if (!text) return '';

    if (text.length > length) {
        return `${text.substring(0, length)}...`;
    }

    return text;
};

const toRem = (value) => {
    return value / 10;
};

const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

const getDaysArray = (from, to) => {
    const dates = [];
    let fromToDate;

    for (fromToDate = new Date(from); fromToDate <= new Date(to); fromToDate.setDate(fromToDate.getDate() + 1)) {
        dates.push(new Date(fromToDate));
    }
    return dates;
};

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const capitalizeAllFirstLetters = (string) =>
    string
        .split(' ')
        .map((s) => capitalizeFirstLetter(s))
        .join(' ');

const humanizeString = (string) =>
    string.replace(/(?:_| |\b)(\w)/g, function ($1) {
        return $1.toUpperCase().replace('_', ' ');
    });

const stringReplaceAstericsWith = (string, before, after) => string.replace(/\*(\S[^\*]+\S)\*/g, `${before}$1${after}`);

const generateId = () => {
    return Math.random().toString(36).substring(2) + new Date().getTime().toString(36);
};

const USDMoneyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const USDMoneyFormatRounded = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const percentageRounded = new Intl.NumberFormat('en-US', {
    style: 'percent',

    minimumFractionDigits: 0, // (will print .3034 as 30%)
    maximumFractionDigits: 0, // (will print .3034 as 30%)
});

const clearHtmlTags = (value, replacedValue = '') => {
    return value.replace(/<[^>]*>?/gm, replacedValue);
};

const getAddressComponent = (components) => {
    let postalCode = '';
    let city = '';
    let street = '';
    let street_number = '';
    let state = '';

    for (const component of components) {
        if (component.types.includes('administrative_area_level_1')) state = component.long_name;
        if (component.types.includes('postal_code')) postalCode = component.long_name;
        if (
            component.types.includes('locality') ||
            component.types.includes('postal_town') ||
            component.types.includes('sublocality_level_1')
        )
            city = component.long_name;
        if (
            component.types.includes('route') ||
            component.types.includes('sublocality_level_4') ||
            component.types.includes('subpremise')
        )
            street = component.long_name;
        if (component.types.includes('street_number') || component.types.includes('premise'))
            street_number = component.long_name;
    }

    if (!city && state) {
        city = state;
    }

    return {
        city,
        state,
        street,
        street_number,
        postalCode,
    };
};

const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

const lockScreenCustom = () => {
    const isDesktop = window.innerWidth > 1023;

    const htmlElement = document.documentElement;
    const headerHolder = document.querySelector('.header .header__holder');

    if (!htmlElement || !headerHolder) {
        return;
    }

    let scrollbarWidthBefore = window.innerWidth - htmlElement.clientWidth;
    htmlElement.style.overflow = 'hidden';

    if (scrollbarWidthBefore > 0 && isDesktop) {
        let scrollbarWidthAfter = htmlElement.clientWidth - htmlElement.offsetWidth;
        let scrollbarWidth = scrollbarWidthBefore - scrollbarWidthAfter;

        headerHolder.style.paddingRight = `${scrollbarWidth}px`;
        htmlElement.style.paddingRight = `${scrollbarWidth}px`;
    }
};

const unlockScreenCustom = () => {
    const htmlElement = document.documentElement;
    const headerHolder = document.querySelector('.header .header__holder');

    if (htmlElement) {
        htmlElement.style.overflow = '';
        htmlElement.style.paddingRight = '';
    }

    if (headerHolder) {
        headerHolder.style.paddingRight = '';
    }
};

const getAssetSrc = (fileName) => {
    const path = `../../assets/temp/${fileName}`;
    const modules = import.meta.glob('../../assets/temp/**/*', { eager: true });

    const mod = modules[path];
    return mod?.default;
};

const calculateProductPriceDiscount = (price, compareAtPrice) => {
    const priceFull = Math.round(price);
    const priceDiscounted = compareAtPrice && compareAtPrice > 0 ? parseFloat(compareAtPrice, 2) : null;

    return priceDiscounted ? Math.round(100 - (priceFull / priceDiscounted) * 100) : null;
};

const abbreviateName = (name) =>
    name &&
    name
        .split(' ')
        .map((partName, index) => (index !== 0 ? partName.slice(0, 1) + '.' : partName))
        .join(' ');

const getNameInitials = (name) =>
    name &&
    name
        .split(' ')
        .map((partName) => partName.slice(0, 1).toUpperCase())
        .join(' ');

const getParsedHtmlContent = (content) => {
    if (!content) {
        return null;
    }
    const options = {
        replace: (node) => {
            if (node.name === 'pre') {
                return <div dangerouslySetInnerHTML={{ __html: node.children[0].data }} />;
            }
        },
    };

    return parse(content, options);
};

const replaceUrlToFrontdesk = (type = '', matches = [], url = '', replaceWith = '') => {
    if (type === 'page' || type === 'product-category') {
        return matches.reduce((acc, match) => {
            return acc.replace(match, replaceWith);
        }, url);
    }

    return url;
};

const calculateDaysLeftInProperty = (activePropertySince) => {
    const allowedAccessDate = import.meta.env.VITE_FRONTDESK_ALLOWED_ACCESS_DAYS;

    if (!allowedAccessDate) {
        return null;
    }

    const activePropertyDate = new Date(activePropertySince);

    const daysLeft = Number(allowedAccessDate) - Math.floor((new Date() - activePropertyDate) / (1000 * 60 * 60 * 24));
    return daysLeft < 1 ? 0 : daysLeft;
};

function createShopifySrcsetWithDensity(
    url,
    sizesProp = [115, 90, 80],
    aspectRatio = '',
    mediaQueryWidths = [0, 768, 1024]
) {
    if (!url) return null;

    const srcsets = sizesProp.map((size, index) => {
        const minWidth = mediaQueryWidths[index];
        const maxWidth = mediaQueryWidths[index + 1];
        const nonRetinaUrl =
            aspectRatio === '1:1'
                ? `${url}&width=${size}&height=${size}&crop=center`
                : Array.isArray(aspectRatio)
                ? `${url}&width=${size}&height=${aspectRatio[index]}&crop=center`
                : `${url}&width=${size}`;
        const retinaUrl =
            aspectRatio === '1:1'
                ? `${url}&width=${size * 2}&height=${size * 2}&crop=center`
                : Array.isArray(aspectRatio)
                ? `${url}&width=${size * 2}&height=${aspectRatio[index] * 2}&crop=center`
                : `${url}&width=${size * 2}`;
        const mediaQuery = maxWidth ? `(max-width: ${maxWidth - 1}px)` : `(min-width: ${minWidth}px)`;

        return {
            media: mediaQuery,
            srcset: `${nonRetinaUrl} 1x, ${retinaUrl} 2x`,
        };
    });

    const sizes = srcsets
        .map((s, i) => {
            return `${s.media} ${sizesProp[i]}px`;
        })
        .join(', ');

    return {
        srcsets,
        sizes,
    };
}

const groupUpsellProductsByCategory = (allProducts, allCategories, excludeAllFilterFromGroup = false) =>
    allCategories.reduce((productsCollection, categoryFilter) => {
        const categoryName = categoryFilter.handle;
        let filteredProducts = allProducts;

        if (categoryName !== 'all') {
            filteredProducts = allProducts.filter((upsellProduct) => {
                return typeof upsellProduct.tags !== 'undefined' && upsellProduct.tags.includes(categoryFilter.handle);
            });
        }

        if (excludeAllFilterFromGroup && categoryName === 'all') {
            return productsCollection;
        }

        return {
            ...productsCollection,
            [categoryName]: filteredProducts,
        };
    }, {});

const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || !Number(phoneNumber)) {
        return '';
    }

    if (phoneNumber.length <= 4) {
        const isStartingWithPlus = phoneNumber[0] === '+';
        const formatedPhoneNumber = isStartingWithPlus ? phoneNumber.slice(1) : phoneNumber;
        return formatedPhoneNumber;
    }

    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    return `+${parsedPhoneNumber.countryCallingCode} ${parsedPhoneNumber.formatNational()}`;
};

const clearPageQuery = () => {
    let url = location.toString();
    url = url.replace(/[&?]page=\d+/g, '');
    history.replaceState({}, null, url);
};

const generatePromoBarHeightVariableStyles = () => {
    const { props, component } = usePage();
    const isDesktop = useMediaQuery('(min-width: 1024px) and (hover: hover)');
    const isTopBarAvaialable = props?.globals?.settings?.data?.topBar?.caption;
    const isHostHomepage = component === 'Homepage';
    const resetedVariables = {
        '--header-top-promo-bar-height-mobile': '0px',
        '--header-top-promo-bar-height-desktop': '0px',
    };

    // desktop
    if (isDesktop) {
        return isTopBarAvaialable ? {} : resetedVariables;
    }
    // mobile
    if (!isTopBarAvaialable || !isHostHomepage) {
        return resetedVariables;
    }

    return {};
};

const getSrcParts = (url) => {
    let parts = url.match(/responsive_(\d+)_(\d+)\.[A-z0-9]{2,5}$/);

    const imageWidth = parseInt(parts[1]);
    const imageHeight = parseInt(parts[2]);

    return { imageWidth, imageHeight };
};

const srcSetMinBoundary = (src, minWidth = 0, minHeight = 0) => {
    if (!src || isEmpty(src)) {
        return;
    }

    const srcSetFiltered = src.filter((s) => {
        let pass = true;

        const { imageWidth, imageHeight } = getSrcParts(s);

        if (minWidth > 0 && imageWidth < minWidth) {
            pass = false;
        }

        if (minHeight > 0 && imageHeight < minHeight) {
            pass = false;
        }

        return pass;
    });

    // Return largest image for fallback
    return isEmpty(srcSetFiltered) ? src[0] : srcSetFiltered;
};

const srcSetMaxBoundary = (src, maxWidth = 0, maxHeight = 0) => {
    if (!src || isEmpty(src)) {
        return;
    }

    const srcSetFiltered = src.filter((s) => {
        let pass = true;

        const { imageWidth, imageHeight } = getSrcParts(s);

        if (maxWidth > 0 && maxWidth < imageWidth) {
            pass = false;
        }

        if (maxHeight > 0 && maxHeight < imageHeight) {
            pass = false;
        }

        return pass;
    });

    // Return smallest image for fallback
    return isEmpty(srcSetFiltered) ? src[src.length - 1] : srcSetFiltered;
};

const checkActivePlatformPage = () => {
    const defaultActiveShoppingPlatformPages = ['shop', 'categories', 'products', 'product'];

    const urlParts = window ? window.location.pathname.split('/').filter((path) => path) : [];

    if (urlParts.length === 0 || defaultActiveShoppingPlatformPages.includes(urlParts[0])) {
        return 'shopping';
    } else if (urlParts.includes('brands') || urlParts.includes('brand')) {
        return 'brands';
    } else if (urlParts.includes('smartlists')) {
        return 'smartLists';
    } else if (urlParts.includes('cms')) {
        return 'frontdesk';
    } else {
        return null;
    }
};

const resolveBlockInfoBasedOnGuideType = (block, level, guideType, isExpanded, activePage) => {
    const { subtype: type } = block;
    const isLevelThree = level === 3;

    let isContainer, isContent, showInnerContentBlocks, hideExpandCueArrow;

    switch (guideType) {
        case 'local-guide':
            isContainer = type === 'container' && level < 2;
            isContent = type === 'content' || level >= 2;
            showInnerContentBlocks = false;
            hideExpandCueArrow = false;
            break;

        case 'house-guide':
            isContainer = type === 'container';
            isContent = type === 'content';
            showInnerContentBlocks = isLevelThree;
            hideExpandCueArrow = (isContent && isExpanded) || isLevelThree || activePage;
            break;

        case 'upsells':
            isContainer = false;
            isContent = true;
            showInnerContentBlocks = false;
            hideExpandCueArrow = false;
            break;
    }

    return {
        isContainer,
        isContent,
        showInnerContentBlocks,
        hideExpandCueArrow,
    };
};

const getUniqueAndValidFontawesomeIcons = (icons) => {
    const uniqueIcons = new Map();

    return icons.reduce((filtered, icon) => {
        if (icon.iconName && !uniqueIcons.has(icon.iconName)) {
            uniqueIcons.set(icon.iconName, true);
            filtered.push(icon);
        }
        return filtered;
    }, []);
};

const recursivelyAddBlock = (blocks, targetBlock, contentBlocksToAdd = []) => {
    return blocks.map((block) => {
        if (block.id === targetBlock.id) {
            block.isExpanded = true;

            const updatedSubSections = block.children ? [...block.children, ...contentBlocksToAdd] : contentBlocksToAdd;

            return {
                ...block,
                children: updatedSubSections,
            };
        } else if (block.children && block.children.length > 0) {
            return {
                ...block,
                children: recursivelyAddBlock(block.children, targetBlock, contentBlocksToAdd),
            };
        }

        return block;
    });
};

const findBlockById = (blocks, targetId) => {
    for (const block of blocks) {
        if (block.id === targetId) {
            return block;
        }

        if (block?.children && block?.children.length > 0) {
            const result = findBlockById(block.children, targetId);

            if (result !== null) {
                return result;
            }
        }
    }
    return null;
};

const findParentByChildId = (items, childId) => {
    if (!items || isEmpty(items) || !childId) {
        return;
    }

    return items.find(
        (currentItem) =>
            currentItem.children &&
            (currentItem.children.find((child) => child.id === childId) ||
                findParentByChildId(currentItem.children, childId))
    );
};

const findBlockBySlug = (blocks, slug) => {
    for (const block of blocks) {
        if (block.slug === slug) {
            return block;
        }

        if (block?.children && block?.children.length > 0) {
            const result = findBlockBySlug(block.children, slug);

            if (result !== null) {
                return result;
            }
        }
    }
    return null;
};

const getPlaceCordinates = (place) => {
    const placeData = place?.place;

    if (!placeData) {
        return null;
    }

    const placeCords = {
        lat: parseFloat(placeData?.lat),
        lng: parseFloat(placeData?.lng),
    };

    return placeCords;
};

const optimisticReactDndOrderUpdate = (result, dispatchSetPages, staticItemsCount = 0, onFail = () => {}) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
        onFail();
        return;
    }

    const previousItemOrder = source.index;

    const parentId = parseInt(destination.droppableId, 10) || null;

    const itemId = parseInt(draggableId, 10);
    const newItemOrder = destination.index;
    let reorderedItems = [];

    if (previousItemOrder === newItemOrder) {
        onFail();
        return;
    }

    if (dispatchSetPages && typeof dispatchSetPages === 'function') {
        dispatchSetPages((previousPages) => {
            let reorderedPages = [...previousPages];

            if (!parentId) {
                const draggedItem = previousPages[previousItemOrder + staticItemsCount];
                reorderedPages.splice(previousItemOrder + staticItemsCount, 1);
                reorderedPages.splice(newItemOrder + staticItemsCount, 0, draggedItem);

                reorderedItems = reorderedPages;
            } else {
                const draggedItemParent = findBlockById(reorderedPages, parentId);

                if (draggedItemParent) {
                    const children = draggedItemParent.children;

                    if (children) {
                        const draggedItem = children[previousItemOrder];
                        children.splice(previousItemOrder, 1);
                        children.splice(newItemOrder, 0, draggedItem);

                        reorderedItems = children;
                    }
                }
            }

            return reorderedPages;
        });
    }

    return {
        reorderedItems,
        parentId,
        itemId,
    };
};

const createMarker = ({ lat, lng, iconUrl, onClick = () => null, slug, id }) => {
    if (typeof window === 'undefined') {
        return {};
    }

    const marker = new google.maps.Marker({
        position: {
            lat,
            lng,
        },
        icon: {
            url: iconUrl,
        },
        slug,
        id,
    });

    marker.addListener('click', () => {
        onClick(marker);
    });

    return marker;
};

const downloadImage = (url, filename = 'download') => {
    let downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.target = '_blank';
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

const formatPriceToRender = (price, forceDecimals = true) => {
    if (price?.toString().startsWith('$')) {
        return price;
    }

    const parsedPrice = parseFloat(price);
    const fixTwoDecimals = forceDecimals || !Number.isInteger(parsedPrice);
    return fixTwoDecimals ? USDMoneyFormat.format(parsedPrice.toFixed(2)) : `$${parsedPrice}`;
};

const checkIsValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

const getOffsetTop = (element, container) => {
    let offsetTop = 0;

    while (element && element !== container) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }

    return offsetTop;
};

const openGoogleMapCordinates = (cordinates) => {
    const lat = cordinates.lat;
    const lng = cordinates.lng;

    if (!lat || !lng) {
        console.error('Missing lat/lng values');
        return;
    }

    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
};

const openGoogleMapAddress = (address) => {
    if (!address) {
        console.error('Missing address value');
        return;
    }

    const encodedAddress = encodeURIComponent(address);

    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank');
};

const getDefaultPageActiveNav = (items = []) => {
    const currentRouteAlias = route().current();
    let activeItem = null;
    let parent = null;

    const findActiveItemRecursive = (items, parentItem = null) => {
        for (let item of items) {
            if (Array.isArray(item.activeRoutesMap)) {
                // handle multiple route cases as active on this item
                if (item.activeRoutesMap.some((route) => route === currentRouteAlias)) {
                    activeItem = item;
                    parent = parentItem;
                    break;
                }
            }

            if (item.routeAlias === currentRouteAlias) {
                activeItem = item;
                parent = parentItem;
                break;
            }

            if (item.subNav) {
                findActiveItemRecursive(item.subNav, item);

                if (activeItem) {
                    break;
                }
            }
        }
    };

    findActiveItemRecursive(items);

    return {
        item: activeItem,
        parent,
    };
};

const getTimeDifference = (refTime, isShort = false) => {
    if (!refTime) {
        return;
    }

    const updatedAt = new Date(refTime);
    const now = new Date();
    const timeDifferenceInSeconds = differenceInSeconds(now, updatedAt);

    if (timeDifferenceInSeconds < 60) {
        return 'just now';
    }

    let timeString = formatDistanceToNowStrict(updatedAt, { addSuffix: true });

    if (isShort) {
        const [value, unit, suffix] = timeString.split(' ');

        const shortUnit = unit.startsWith('second')
            ? 's'
            : unit.startsWith('minute')
            ? 'm'
            : unit.startsWith('hour')
            ? 'h'
            : unit.startsWith('day')
            ? 'd'
            : unit.startsWith('month')
            ? 'mo'
            : unit.startsWith('year')
            ? 'y'
            : '';

        timeString = `${value}${shortUnit} ${suffix}`;
    }

    return timeString;
};

const formatTo24HourTimeWithAmPm = (timestamp) => {
    if (!timestamp) {
        return '';
    }

    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = format(date, 'mm');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const hoursStr = hours < 10 ? '0' + hours : hours;

    return `${hoursStr}:${minutes}${ampm}`;
};

const getTimeDividerLabel = (timestamp) => {
    if (!timestamp) return '';

    const date = new Date(timestamp);

    if (isToday(date)) {
        return '';
    }

    if (isYesterday(date)) {
        return 'Yesterday';
    }

    if (isThisWeek(date)) {
        return format(date, 'EEEE');
    }

    return format(date, 'MMMM d, yyyy');
};

const formatHouseGuideSlotTemplates = (templates = []) => {
    const processedHouseGuideSlotTemplates = {};

    templates.forEach((template) => {
        const l1Key = `${template.l1}`;

        if (!processedHouseGuideSlotTemplates[l1Key]) {
            processedHouseGuideSlotTemplates[l1Key] = {
                id: l1Key,
                title: template.l1,
                icon: template.l1_icon,
                sub: {},
            };
        }

        const l2Key = `${l1Key}-${template.l2}`;

        if (!processedHouseGuideSlotTemplates[l1Key].sub[l2Key]) {
            processedHouseGuideSlotTemplates[l1Key].sub[l2Key] = {
                id: l2Key,
                title: template.l2,
                icon: template.l2_icon,
                items: [],
            };
        }

        processedHouseGuideSlotTemplates[l1Key].sub[l2Key].items.push({
            id: `${template.id}-l3`,
            title: template.l3,
            slot_template_uuid: template.uuid,
        });
    });

    let transformedData = { 1: [], 2: [], 3: [] };

    Object.keys(processedHouseGuideSlotTemplates).forEach((l1Key) => {
        transformedData['1'].push({
            id: processedHouseGuideSlotTemplates[l1Key].id,
            title: processedHouseGuideSlotTemplates[l1Key].title,
            icon: processedHouseGuideSlotTemplates[l1Key].icon,
        });

        Object.keys(processedHouseGuideSlotTemplates[l1Key].sub).forEach((l2Key) => {
            transformedData['2'].push({
                id: processedHouseGuideSlotTemplates[l1Key].sub[l2Key].id,
                title: processedHouseGuideSlotTemplates[l1Key].sub[l2Key].title,
                icon: processedHouseGuideSlotTemplates[l1Key].sub[l2Key].icon,
            });

            processedHouseGuideSlotTemplates[l1Key].sub[l2Key].items.forEach((l3Item) => {
                transformedData['3'].push({
                    id: l3Item.id,
                    title: l3Item.title,
                    // TODO: should we add the "default" content template here?
                    // content: '<p>Default content</p>',
                    slot_template_uuid: l3Item.slot_template_uuid,
                });
            });
        });
    });

    return {
        processedHouseGuideSlotTemplates,
        transformedData,
    };
};

const handlePropertySelect = (property, options = {}) => {
    const { onFinish = () => {}, reload = true } = options;

    if (property.selected) {
        return onFinish();
    }

    axios
        .post(
            route('properties.select'),
            { id: property.id },
            {
                withCredentials: true,
            }
        )
        .then(() => {
            if (reload) {
                router.get(
                    route(route().current()),
                    {},
                    {
                        preserveScroll: true,
                        onFinish,
                    }
                );
            } else {
                onFinish();
            }
        });
};

const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'kB', 'mB', 'gB', 'tB', 'pB', 'eB', 'zB', 'yB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const getGravatarUrl = (email, size = 32, defaultImage = 'identicon') => {
    if (!email) {
        return;
    }

    const trimmedEmail = email.trim().toLowerCase();
    const hash = md5(trimmedEmail);

    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${defaultImage}`;
};

export {
    truncateText,
    toRem,
    getRandomInt,
    getDaysArray,
    capitalizeFirstLetter,
    capitalizeAllFirstLetters,
    humanizeString,
    stringReplaceAstericsWith,
    generateId,
    USDMoneyFormat,
    USDMoneyFormatRounded,
    percentageRounded,
    formatPriceToRender,
    clearHtmlTags,
    getAddressComponent,
    getAssetSrc,
    pluralize,
    lockScreenCustom,
    unlockScreenCustom,
    calculateProductPriceDiscount,
    abbreviateName,
    getNameInitials,
    getParsedHtmlContent,
    replaceUrlToFrontdesk,
    calculateDaysLeftInProperty,
    createShopifySrcsetWithDensity,
    groupUpsellProductsByCategory,
    formatPhoneNumber,
    clearPageQuery,
    generatePromoBarHeightVariableStyles,
    srcSetMinBoundary,
    srcSetMaxBoundary,
    checkActivePlatformPage,
    resolveBlockInfoBasedOnGuideType,
    getUniqueAndValidFontawesomeIcons,
    recursivelyAddBlock,
    findBlockById,
    findParentByChildId,
    findBlockBySlug,
    getPlaceCordinates,
    optimisticReactDndOrderUpdate,
    createMarker,
    downloadImage,
    getOffsetTop,
    checkIsValidEmail,
    openGoogleMapCordinates,
    openGoogleMapAddress,
    getDefaultPageActiveNav,
    getTimeDifference,
    formatHouseGuideSlotTemplates,
    formatTo24HourTimeWithAmPm,
    handlePropertySelect,
    formatFileSize,
    getGravatarUrl,
    getTimeDividerLabel,
};
