/**
 * External dependencies
 */
import { createContext, useContext, useMemo, useRef, useState } from 'react';
import { usePage } from '@inertiajs/react';

/**
 * Internal dependencies
 */
import { checkActivePlatformPage } from '@/common/helpers/helpers';

const PLATFORM_CLOSE_TIMEOUT = 250;

const HeaderContext = createContext({});

export const HeaderProvider = ({ children, previewPageLink, area }) => {
    const [activeDrawer, setActiveDrawer] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerAccountOpen, setDrawerAccountOpen] = useState(false);
    const [activeMegaMenu, setActiveMegaMenu] = useState(false);
    const [activeMegaMenuBar, setActiveMegaMenuBar] = useState(false);
    const [drawerAccount, setDrawerAccount] = useState(false);
    const [drawerNotifications, setDrawerNotifications] = useState(false);
    const [switchPropertiesOpen, setSwitchPropertiesOpen] = useState(false);
    const [showAddProperties, setShowAddProperties] = useState(false);
    const [addPropertySuccess, setAddPropertySuccess] = useState(false);
    const [showSearchMobile, setShowSearchMobile] = useState(false);
    const [shouldCollapse, setShouldCollapse] = useState(false);
    const [scrollPositionTrigger, setScrollPositionTrigger] = useState(0);
    const [activePlatform, setActivePlatform] = useState(checkActivePlatformPage());
    const [megaMenuActive, setMegaMenuActive] = useState(false);
    const [isFolded, setIsFolded] = useState(false);
    const [isManuallyCollapsed, setIsManuallyCollapsed] = useState(false);

    const { domainRouteName } = usePage().props;

    const megaMenuCloseTimeoutId = useRef(null);

    const ACCOUNT_NAV = useMemo(
        () => [
            [
                {
                    url: route(`${domainRouteName}.account.main`, {
                        slug: 'details',
                    }),
                    label: 'Account',
                    smaller: false,
                    external: true,
                },
                {
                    url: route(`${domainRouteName}.account.orders`),
                    label: 'Orders',
                    smaller: false,
                    external: true,
                },
                {
                    url: route(`${domainRouteName}.account.properties`),
                    label: 'Properties',
                    smaller: false,
                    external: true,
                },
            ],
            [
                {
                    url: 'https://tryinhouse.stonly.com/kb/en',
                    label: 'Help',
                    smaller: true,
                    external: true,
                },
                {
                    url: 'mailto:support@tryinhouse.com',
                    label: 'Contact Us',
                    smaller: true,
                    external: true,
                },
                {
                    url: route('logout'),
                    method: 'post',
                    label: 'Log out',
                    smaller: true,
                },
            ],
        ],
        []
    );

    const toggleDrawerOpen = () => {
        setDrawerOpen((previousState) => !previousState);
    };

    const toggleAccountDrawerOpen = () => {
        setDrawerAccountOpen((previousState) => !previousState);
    };

    const toggleMegaMenu = (value) => {
        setActiveMegaMenu(value);
    };

    const toggleMegaMenuBar = (value) => {
        setActiveMegaMenuBar(value);
    };

    const toggleSearchMobile = () => {
        setShowSearchMobile((previousState) => !previousState);
    };

    return (
        <HeaderContext.Provider
            value={{
                iconSize: 16,
                accountNavLinks: ACCOUNT_NAV,
                activeDrawer,
                setActiveDrawer,
                drawerOpen,
                setDrawerOpen,
                toggleDrawerOpen,
                drawerAccountOpen,
                setDrawerAccountOpen,
                toggleAccountDrawerOpen,
                activeMegaMenu,
                toggleMegaMenuBar,
                activeMegaMenuBar,
                toggleMegaMenu,
                drawerNotifications,
                setDrawerNotifications,
                drawerAccount,
                setDrawerAccount,
                showAddProperties,
                setShowAddProperties,
                addPropertySuccess,
                setAddPropertySuccess,
                switchPropertiesOpen,
                setSwitchPropertiesOpen,
                showSearchMobile,
                setShowSearchMobile,
                toggleSearchMobile,
                scrollPositionTrigger,
                setScrollPositionTrigger,
                shouldCollapse,
                setShouldCollapse,
                activePlatform,
                setActivePlatform,
                megaMenuActive,
                setMegaMenuActive,
                megaMenuCloseTimeoutId,
                isFolded,
                setIsFolded,
                isManuallyCollapsed,
                setIsManuallyCollapsed,
                area,
                previewPageLink,
                PLATFORM_CLOSE_TIMEOUT,
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
};

export function useHeader() {
    return useContext(HeaderContext);
}
